import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LoginstateService } from './loginstate.service'
import { User } from './types'
import * as CryptoJS from 'crypto-js';
import { EncryptionService } from '../services/encryption.service'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private endpoint = 'https://microservice-dot-dev-splicer-204213.ey.r.appspot.com'
  private authUser = '__auth_user__'
  private expires = '__auth_expires__'
  private JWTToken = '__jwt_token__'
  public authentificationKey = 'khM0UwF7y0ItxRuazsE1RRRdX0WwLKPDjgF2PEfL'


  constructor(
    private http: HttpClient,
    private loginStateService: LoginstateService,
    private encryptionService: EncryptionService
  ) {}

  setUser(user: User): void {
    this.loginStateService.setUser(user)
    localStorage.setItem(this.authUser, JSON.stringify(user))
  }

  setExpirationToken(token: number): void {
    localStorage.setItem(this.expires, JSON.stringify(token))
  }

  getExpirationToken(): number {
    const expiresToken = localStorage.getItem(this.expires)
    return Number(expiresToken)
  }


  getUser(): User | null {
    try {
      const authToken =
        JSON.parse(localStorage.getItem(this.authUser)) || (null as User | null)
      return authToken
    } catch {
      return null
    }
  }

  setJWTToken(jwtTokeb: string): void {
    localStorage.setItem(this.JWTToken, jwtTokeb)
  }

  getJWTToken(): string  {
    try {
      const authToken = localStorage.getItem(this.JWTToken)
      return authToken
    } catch {
      return null
    }
  }

  removeUser(): void {
    localStorage.removeItem(this.authUser)
  }

  removeExpiresToken(): void {
    localStorage.removeItem(this.expires)
  }

  removeJWTToken(): void {
    localStorage.removeItem(this.JWTToken)
  }

  isAuthenticated(): boolean {
    const auth = localStorage.getItem(this.authUser)
    try {
      const authObj = JSON.parse(auth) as User
      return !!authObj
    } catch {
      return false
    }
  }

  getUserByEmail(email: string): Observable<any> {
    const emailEncrypted = this.encryptionService.encryptPassword(email)
    return this.http.get<any>(
      `${this.endpoint}/getUserByEmail?auth=khM0UwF7y0ItxRuazsE1RRRdX0WwLKPDjgF2PEfL&email=${emailEncrypted}`
    )
  }

  getAuthParameters(email: string): Observable<any> {
    const emailEncrypted = this.encryptionService.encryptPassword(email)
    return this.http.get<any>(
      `${this.endpoint}/getAuthParametersAlternative?auth=khM0UwF7y0ItxRuazsE1RRRdX0WwLKPDjgF2PEfL&email=${emailEncrypted}`
    )
  }

  logout() {
    this.removeUser(),
    this.removeJWTToken()
  }
}
