import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { LoginService } from './login.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginSerivce : LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request and add the x-apikey header
        const modifiedRequest = request.clone({
          headers: request.headers.set(
            'x-apikey',
            'khM0UwF7y0ItxRuazsE1RRRdX0WwLKPDjgF2PEfL'
          ).append(
            "Token", this.loginSerivce.getJWTToken()
          )
        });
        return next.handle(modifiedRequest);
      }
}
