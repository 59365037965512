import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private pwEncryptionKey = 'AhFLJiuBfLRCfE+WcUgkiVsjOR829MTK4KlpvnR3QLc=';

  constructor() {}

  encryptPassword(password: string): string {
    const key = CryptoJS.enc.Base64.parse(this.pwEncryptionKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(password, key, { iv });
    const finalKey = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
    return encodeURIComponent(finalKey);
  }
}
