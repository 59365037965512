import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { StateService } from '../services/state.service'
import { User } from './types'

interface LoginState {
  user: User | null
}

const initialState: LoginState = {
  user: null
}

@Injectable({
  providedIn: 'root'
})
export class LoginstateService extends StateService<LoginState> {
  user$: Observable<User | null> = this.select((state) => state.user)

  constructor() {
    super(initialState)
  }

  setUser(user: User) {
    this.setState({
      user
    })
  }
}
